<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card v-if="hasIntervals" class="m-0" style="border-bottom: 1px solid #e0e0e0">
        <chart :items="items" :height="80"></chart>
      </b-card>
      <b-card>
        <b-table v-if="rows && rows.length" :fields="table.fields" :items="rows" :filter="filter" responsive>
          <template :class="{ edited: data.item.edited }" slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <span slot="text" @click="expand(data.item)">
                <template v-if="data.value">
                  <template v-if="data.item.id !== 'all'">{{
                    Date.parse(data.value) | formatDate('dddd DD MMM YYYY')
                  }}</template>
                  <template v-else>{{ data.value }}</template>
                </template>
                <template v-else-if="data.item.user">
                  <b-link v-if="data.item.user.employee" :to="'/employees/' + data.item.user.employee.id"
                    >{{ data.item.user.name | slicer }}
                  </b-link>
                  <template v-else>{{ data.item.user.name }}</template>
                </template>
              </span>
            </expander>
          </template>

          <template :class="{ edited: data.item.edited }" slot="interval" slot-scope="data">
            <span
              v-if="data.item.started_at && data.item.completed_at"
              data-link
              @click.stop.prevent="showScreens($event, data.item)"
              >{{ data.item.started_at | formatDate('HH:mm') }} -
              {{ data.item.completed_at | formatDate('HH:mm') }}</span
            >
          </template>

          <template :class="{ edited: data.item.edited }" slot="seconds" slot-scope="data">
            {{ data.value | formatDuration }}
          </template>

          <template :class="{ edited: data.item.edited }" slot="project" slot-scope="data">
            <template v-if="data.value">
              <b-link :to="'/projects/list?filters[projects][id]=' + data.value.id" :title="data.value.name"
                >{{ data.value.name | slicer }}
              </b-link>
            </template>
            <template v-else-if="data.item.level">-</template>
          </template>

          <template :class="{ edited: data.item.edited }" slot="task" slot-scope="data">
            <template v-if="data.value">
              <b-link :to="'/tasks/list?filters[tasks][id]=' + data.value.id" :title="data.value.name"
                >{{ data.value.name | slicer }}
              </b-link>
            </template>
            <template v-else-if="data.item.level">-</template>
          </template>

          <template :class="{ edited: data.item.edited }" slot="worth" slot-scope="data">
            {{ data.value | formatNumber }}&nbsp;&#8381;
          </template>

          <template :class="{ edited: data.item.edited }" slot="cost" slot-scope="data">
            {{ data.value | formatNumber }}&nbsp;&#8381;
          </template>

          <template :class="{ edited: data.item.edited }" slot="rate" slot-scope="data">
            {{ data.value | formatNumber }}&nbsp;&#8381;
          </template>

          <template :class="{ edited: data.item.edited }" slot="userRate" slot-scope="data">
            {{ data.value | formatNumber }}&nbsp;&#8381;
          </template>

          <template :class="{ edited: data.item.edited }" slot="margin" slot-scope="data">
            {{ (data.item.cost - data.item.worth) | formatNumber }}&nbsp;&#8381;
          </template>
        </b-table>
        <template v-else> По заданным критериям интервалы не найдены </template>
        <b-modal></b-modal>
      </b-card>
    </div>
  </list>
  <span class="loading" v-else-if="!isRouteComponent">Загрузка...</span>
</template>

<script>
  import api from '../../../assets/js/api'
  import notification from '../../../assets/js/notification'

  import stairsMixins from '../../../mixins/stairs'
  import entityMixins from '../../../mixins/entity'

  export default {
    name: 'report',
    components: {
      list: () => import('../../../components/List'),
      expander: () => import('../../../components/Expander'),
      chart: () => import('./Chart'),
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'name',
              label: '&nbsp;',
            },
            {
              key: 'project',
              label: 'Проект',
            },
            {
              key: 'task',
              label: 'Задача',
            },
            {
              key: 'interval',
              label: 'Интервал',
              class: 'text-center',
            },
            {
              key: 'seconds',
              label: 'Время',
              class: 'text-center',
            },
            {
              key: 'rate',
              label: 'На руки',
              class: 'text-center',
            },
            {
              key: 'worth',
              label: 'Себестоимость',
              class: 'text-center',
              roles: ['root'],
            },
            // {
            //   key: 'cost',
            //   label: 'Стоимость',
            //   class: 'text-center',
            //   roles: ['root'],
            // },
            // {
            //   key: 'margin',
            //   label: 'Маржа',
            //   class: 'text-center',
            //   roles: ['root'],
            // },
          ],
        },
        filters: [
          {
            name: 'date',
            label: {
              from: 'с',
              to: 'по',
            },
            width: 3,
            type: 'date',
            range: true,
          },
          {
            name: 'employee_id',
            label: 'Выберите сотрудника',
            type: 'select',
            ajax: {
              entity: 'employees',
              customLabel(item) {
                return item['first_name'] + ' ' + item['surname']
              },
            },
          },
          {
            name: 'project_id',
            label: 'Выберите проект',
            type: 'select',
            ajax: {
              entity: 'projects',
            },
          },
          {
            name: 'task_id',
            label: 'Выберите задачу',
            type: 'select',
            ajax: {
              entity: 'tasks',
            },
          },
        ],
      }
    },
    computed: {
      hasIntervals() {
        return this.items && !!this.items.filter(item => item.date).length
      },
    },
    methods: {
      showScreens(e, item) {
        require('@fancyapps/fancybox')
        api.base
          .get({
            path: 'intervals/td-screens',
            params: {
              id: item.id,
            },
          })
          .then(result => {
            if (result.length) {
              $.fancybox.open(
                result.map(item => {
                  return {
                    src: item.src,
                    opts: {
                      caption: item.caption,
                    },
                  }
                }),
                {
                  thumbs: {
                    autoStart: true,
                  },
                }
              )
            } else {
              notification['warning']('Скриншоты не найдены', 'Внимание')
            }
          })
      },
      resultHandler(result) {
        this.dataPrepare(result)
      },
      postPrepare(item) {
        if (item.edited) {
          this.$set(item, '_rowVariant', 'warning')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-warning {
    [data-link] {
      color: #fff;
    }
  }
</style>
